import React from 'react';
import { Switch } from 'react-router-dom';
import PageRoute from 'components/PageRoute';
// import Navbar from 'components/Navbar';
// import { useDispatch } from 'react-redux';

// import { fetchSIInfo } from 'store/actionCreators/si';

const HomePage = React.lazy(() => import('./Home'));
const RoomsStatusPage = React.lazy(() => import('./RoomsStatus'));
// const TickerPage = React.lazy(() => import('./Ticker'));

const App = (): JSX.Element => {
  // let hostname = '';
  // if (typeof window !== 'undefined') {
  //   hostname = window.location.host;
  //   // console.log(hostname);
  // }
  // const dispatch = useDispatch();
  // dispatch(fetchSIInfo());

  return (
    <div className="App">
      {/* <Navbar /> */}
      <Switch>
        <PageRoute exact path="/:siUrl" component={HomePage} />
        <PageRoute path="/:siUrl/rooms-status" component={RoomsStatusPage} />
        {/* <PageRoute exact path="/" component={HomePage} />
        <PageRoute exact path="/rooms-status" component={RoomsStatusPage} /> */}
        {/* <PageRoute exact path="/ticker" component={TickerPage} /> */}
      </Switch>
    </div>
  );
};

export default App;
