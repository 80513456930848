import { createSlice } from '@reduxjs/toolkit';

import { fetchSIInfo } from 'store/actionCreators/si';

const si = createSlice({
  name: 'si',
  initialState: { siName: '到訪訂房管理系統' } as SIInfo,
  reducers: {},
  extraReducers: {
    [fetchSIInfo.fulfilled.type]: (state, action) => {
      return action.payload;
    },
  },
});

export default si;
