import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'superagent';

export const fetchSIInfo = createAsyncThunk(
  'fetchSIInfo',
  async (siUrl: string) => {
    const { hostname } = window.location;
    const reLocal = /localhost.*/i;
    let apiUri = 'http://localhost:5001/arke-liff/us-central1';
    if (hostname && !hostname.match(reLocal)) {
      apiUri = 'https://us-central1-arke-liff.cloudfunctions.net';
    }

    const response = await request
      .get(`${apiUri}/api/si-info`)
      .query({ siUrl })
      .then((res) => res.body)
      .catch((err) => {
        console.log(err);
      });

    return response;
  }
);
