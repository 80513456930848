import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const Loading = () => (
  // <Flex alignItems="center" justifyContent="center" h="100vh">
  <Spinner
    thickness="4px"
    speed="0.65s"
    emptyColor="gray.200"
    // color="blue.500"
    color="orange"
    size="xl"
  />
  // </Flex>
);

export default React.memo(Loading);
