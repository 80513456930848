import { combineReducers } from 'redux';
import auth from './auth';
import si from './si';

const rootReducer = combineReducers({
  auth: auth.reducer,
  si: si.reducer,
});

export default rootReducer;
