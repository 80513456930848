import React from 'react';
import ReactDOM from 'react-dom';
import AppProviders from 'containers/AppProviders';
import App from 'pages';
import createBrowserHistory from 'utils/history';
import configureStore from 'utils/store';
import { ChakraProvider } from '@chakra-ui/react';
// import { store } from './helpers';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBuAgV443ZOKrEhMUYJksylLq_y9XmN9DY',
  authDomain: 'arke-liff.firebaseapp.com',
  projectId: 'arke-liff',
  storageBucket: 'arke-liff.appspot.com',
  messagingSenderId: '499439479254',
  appId: '1:499439479254:web:9ec44ee6426d5f127adc77',
  measurementId: 'G-M6TLF6RK1B',
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
// firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: configureStore().dispatch,
  // createFirestoreInstance // <- needed if using firestore
};

ReactDOM.render(
  <AppProviders history={createBrowserHistory()} store={configureStore()}>
    <ChakraProvider>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <App />
      </ReactReduxFirebaseProvider>
    </ChakraProvider>
  </AppProviders>,
  document.getElementById('root')
);
